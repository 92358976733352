/*
 |-----------------------------------------------------------------------------
 | helpers/_animations.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 | Contents
 |
 |  1. Fade out                                                         LN:  17
 |
 */

/*
 |-----------------------------------------------------------------------------
 | Fade out
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	50% {
		opacity: 1;
	}

	100% {
		display: none;
		opacity: 0;
	}
}
