/*
 |-----------------------------------------------------------------------------
 | tokens/_label.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

$label-color: $brand-4;
$label-font-size: type(18px, $font-size-body);
$label-font-weight: 'normal';
