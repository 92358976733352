/*
 |-----------------------------------------------------------------------------
 | tokens/_control.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

$control-background-color: transparent;
$control-border-color: $brand-4;
$control-border-radius: 0;
$control-border-width: 1px;
$control-color: $brand-4;
$control-font-size: type(18px, $font-size-body);
$control-font-weight: 'normal';
$control-padding: $grid-gap 0 div($grid-gap, 4);

// :hover
$control-hover-background-color: rgba($brand-4, 0.25);

// :focus
$control-focus-border-color: $brand-1;
