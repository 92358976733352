/*
 |-----------------------------------------------------------------------------
 | _colours.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 | Contents
 |
 |  1. Alert                                                            LN:  19
 |  2. Brand                                                            LN:  36
 |  3. Neutral                                                          LN:  52
 |
 */

/*
 |-----------------------------------------------------------------------------
 | Alert
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

$error: #e81c4f;
$success: #19cf86;
$warning: #ed734a;

/*
 |-----------------------------------------------------------------------------
 | Brand
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

$brand-1: #4d49be;
$brand-2: #2cccd3;
$brand-3: #25282a;
$brand-4: #d9d9d6;

/*
 |-----------------------------------------------------------------------------
 | Neutral
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

$neutral-0: #ffffff;
$neutral-10: #e5e5e5;
$neutral-20: #cccccc;
$neutral-30: #b2b2b2;
$neutral-40: #999999;
$neutral-50: #7f7f7f;
$neutral-60: #666666;
$neutral-70: #4c4c4c;
$neutral-80: #333333;
$neutral-90: #191919;
$neutral-100: #000000;
