/*
 |-----------------------------------------------------------------------------
 | components/atoms/Input/Input.module.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@import 'styles/bundle';
@import 'styles/tokens/control';
@import 'styles/tokens/label';

.block {
	display: block;
	position: relative;

	&::after {
		@include absolute($right: 0, $bottom: 0, $left: 0);
		@include display($display: 'block', $height: 1px);
		@include z-index('above');
		background-color: $control-focus-border-color;
		content: '';
		opacity: 0;
		transform: scaleX(0);
		transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1),
			opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&.isDisabled {
		@include disabled();
	}

	/* stylelint-disable-next-line selector-class-pattern */
	&.isFocus {
		&::after {
			opacity: 1;
			transform: scaleX(1);
		}
	}
}

.control {
	@include transition('background-color');
	background-color: $control-background-color;
	border-bottom: $control-border-width solid $control-border-color;
	border-radius: $control-border-radius;
	color: $control-color;
	display: block;
	font-size: $control-font-size;
	padding: $control-padding;
	width: 100%;

	&:hover {
		// background-color: $control-hover-background-color;
	}

	&:focus {
		// TODO: State required
	}

	&:active {
		// TODO: State required
	}

	&:not(:placeholder-shown) ~ .label {
		transform: translateY(-116%) scale(0.75);
	}

	&::-webkit-calendar-picker-indicator {
		filter: invert(0.8);
	}
}

.label {
	@include absolute($top: 50%);
	color: $label-color;
	display: inline-block;
	font-size: $label-font-size;
	pointer-events: none;
	transform-origin: left top;
	transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1),
		color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

@include breakpoint('mobile-l') {
	.label {
		font-size: 1.6rem;
	}
}
