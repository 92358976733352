/*
 |-----------------------------------------------------------------------------
 | _variables.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

$box-shadow: 0 10px 0 0 rgba($neutral-100, 0.75);
$curvature: 4px;
$focus: 1px 1px 0 1px rgba($brand-1, 0.25);
$grid-gap: 32px;
$grid-width: 1280px;
$line-length: 75ch;
$menu-width: 375px;
$opacity: 0.5;
$overlay: rgba($neutral-100, 0.25);
$speed: 0.5s;
$tap-area: 44px;
$text-shadow: -1px 1px 2px $neutral-100;
