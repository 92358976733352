/*
 |-----------------------------------------------------------------------------
 | helpers/_mixins.scss
 | v1.0.0
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 | Contents
 |
 |  1. Absolute                                                         LN:
 |  2. Animation                                                        LN:
 |  3. Area                                                             LN:
 |  4. Blur                                                             LN:
 |  5. Breakpoints                                                      LN:
 |  6. Chevron                                                          LN:
 |  7. Container                                                        LN:
 |  8. Disabled                                                         LN:
 |  9. Display                                                          LN:
 | 10. Fixed                                                            LN:
 | 11. Flex                                                             LN:
 | 12. Font face                                                        LN:
 | 13. Font family                                                      LN:
 | 14. Font weight                                                      LN:
 | 15. Font                                                             LN:
 | 16. Grid                                                             LN:
 | 17. Group                                                            LN:
 | 18. Hyperlink                                                        LN:
 | 19. Icon                                                             LN:
 | 20. Outline                                                          LN:
 | 21. Relative                                                         LN:
 | 22. Rotate                                                           LN:
 | 23. Scale                                                            LN:
 | 24. Sticky                                                           LN:
 | 25. Transition                                                       LN:
 | 26. Triangle                                                         LN:
 | 27. Truncate                                                         LN:
 | 28. Z-index                                                          LN:
 |
 */

/*
 |-----------------------------------------------------------------------------
 | Absolute
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin absolute($top: null, $right: null, $bottom: null, $left: null) {
	bottom: $bottom;
	left: $left;
	position: absolute;
	right: $right;
	top: $top;
}

/*
 |-----------------------------------------------------------------------------
 | Animation
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin animation($name, $speed: 5s, $fill-mode: 'forwards') {
	animation: #{$name} $speed #{$fill-mode};
}

/*
 |-----------------------------------------------------------------------------
 | Area
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin area(
	$column-start: null,
	$column-end: null,
	$row-start: null,
	$row-end: null
) {
	grid-column-end: $column-end;
	grid-column-start: $column-start;
	grid-row-end: $row-end;
	grid-row-start: $row-start;
}

/*
 |-----------------------------------------------------------------------------
 | Blur
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin blur($radius) {
	filter: blur($radius);
}

/*
 |-----------------------------------------------------------------------------
 | Breakpoints
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin breakpoint($breakpoint: null) {
	$raw-query: map-get($breakpoints, $breakpoint);

	@if $raw-query {
		$query: if(
			type-of($raw-query) == 'string',
			unquote($raw-query),
			inspect($raw-query)
		);

		@media #{$query} {
			@content;
		}
	}
}

/*
 |-----------------------------------------------------------------------------
 | Chevron
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin chevron(
	$direction,
	$width: null,
	$height: null,
	$thickness: null,
	$color: null
) {
	@if $direction == 'up' {
		transform: rotate(-45deg);
	}

	@if $direction == 'right' {
		transform: rotate(45deg);
	}

	@if $direction == 'down' {
		transform: rotate(135deg);
	}

	@if $direction == 'left' {
		transform: rotate(-135deg);
	}

	border-color: $color;
	border-style: solid;
	border-width: $thickness $thickness 0 0;
	content: '';
	display: inline-block;
	height: $height;
	vertical-align: top;
	width: $width;
}

/*
 |-----------------------------------------------------------------------------
 | Container
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin container($width: $grid-width, $gutter: $grid-gap) {
	margin-bottom: 0;
	margin-left: auto;
	margin-right: auto;
	max-width: $width;
	padding: 0 mul($gutter, 2);
	width: 100%;

	// Breakpoints
	@include breakpoint('mobile-l') {
		padding: 0 $gutter;
	}
}

/*
 |-----------------------------------------------------------------------------
 | Disabled
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin disabled() {
	opacity: $opacity;
	pointer-events: none;
}

/*
 |-----------------------------------------------------------------------------
 | Display
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin display($display: null, $width: null, $height: null) {
	display: #{$display};
	height: $height;
	width: $width;
}

/*
 |-----------------------------------------------------------------------------
 | Fixed
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin fixed($top: null, $right: null, $bottom: null, $left: null) {
	bottom: $bottom;
	left: $left;
	position: fixed;
	right: $right;
	top: $top;
}

/*
 |-----------------------------------------------------------------------------
 | Flex
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin flex($row: null, $column: null, $direction: null, $inline: null) {
	align-items: #{$column};
	display: if($inline, inline-flex, flex);
	flex-direction: #{$direction};
	justify-content: #{$row};
}

/*
 |-----------------------------------------------------------------------------
 | Font face
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin font-face($family, $filename, $weight, $italic: null) {
	@font-face {
		font-family: '#{$family}';
		font-style: if($italic, italic, normal);
		font-weight: $weight;
		src: url('/fonts/#{$filename}.woff2') format('woff2'),
			url('/fonts/#{$filename}.woff') format('woff');
	}
}

/*
 |-----------------------------------------------------------------------------
 | Font family
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin font-family($stack) {
	font-family: map-get($stacks, $stack);
}

/*
 |-----------------------------------------------------------------------------
 | Font weight
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin font-weight($weight) {
	$raw-query: map-get($weights, $weight);

	@if $raw-query {
		$query: if(
			type-of($raw-query) == 'string',
			unquote($raw-query),
			inspect($raw-query)
		);

		font-weight: #{$query};
	}
}

/*
 |-----------------------------------------------------------------------------
 | Font
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin font($family: null, $size: null, $weight: null) {
	@if $family != null {
		@include font-family($family);
	}

	font-size: $size;

	@if $weight != null {
		@include font-weight($weight);
	}
}

/*
 |-----------------------------------------------------------------------------
 | Grid
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin grid($gap: null, $columns: null, $rows: null) {
	display: grid;
	grid-gap: $gap;
	grid-template-columns: $columns;
	grid-template-rows: $rows;
}

/*
 |-----------------------------------------------------------------------------
 | Group
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin group($alignment) {
	@include flex($column: 'center');

	@if $alignment == 'center' {
		justify-content: center;

		> * {
			margin: 0 div($grid-gap, 8);
		}
	}

	@if $alignment == 'left' {
		justify-content: flex-start;

		> * {
			margin-right: div($grid-gap, 4);
		}
	}

	@if $alignment == 'right' {
		justify-content: flex-end;

		> * {
			margin-left: div($grid-gap, 4);
		}
	}
}

/*
 |-----------------------------------------------------------------------------
 | Hyperlink
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin hyperlink($color-1, $color-2: null) {
	color: $color-1;
	position: relative;

	&::after {
		@include absolute($top: 100%, $right: 0, $left: 0);
		@include scale(0, 'x');
		@include transition($animation: 'ease-in-out');
		background-color: if($color-2, $color-2, $color-1);
		content: '';
		height: 2px;
		visibility: hidden;
	}

	&:hover {
		&::after {
			@include scale(1, 'x');
			visibility: visible;
		}
	}

	&:focus {
		// TODO: State required
	}

	&:active {
		// TODO: State required
	}
}

/*
 |-----------------------------------------------------------------------------
 | Icon
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin icon($colour: null, $size) {
	fill: $colour;
	height: $size;
	width: $size;
}

/*
 |-----------------------------------------------------------------------------
 | Outline
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin outline($fill-colour, $stroke-colour, $stroke-width: 0.5px) {
	-webkit-text-fill-color: $fill-colour;
	-webkit-text-stroke: $stroke-width $stroke-colour;
}

/*
 |-----------------------------------------------------------------------------
 | Relative
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin relative($top: null, $right: null, $bottom: null, $left: null) {
	bottom: $bottom;
	left: $left;
	position: relative;
	right: $right;
	top: $top;
}

/*
 |-----------------------------------------------------------------------------
 | Rotate
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin rotate($degrees) {
	transform: rotate($degrees);
}

/*
 |-----------------------------------------------------------------------------
 | Scale
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin scale($zoom, $axis: null) {
	@if $axis != null {
		transform: scaleX($zoom);
	} @else {
		transform: scale($zoom);
	}
}

/*
 |-----------------------------------------------------------------------------
 | Sticky
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin sticky($top: null, $right: null, $bottom: null, $left: null) {
	bottom: $bottom;
	left: $left;
	position: sticky;
	right: $right;
	top: $top;
}

/*
 |-----------------------------------------------------------------------------
 | Transition
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 | ease - specifies a transition effect with a slow start, then fast, then end
 |        slowly (this is default)
 | linear - specifies a transition effect with the same speed from start to end
 | ease-in - specifies a transition effect with a slow start
 | ease-out - specifies a transition effect with a slow end
 | ease-in-out - specifies a transition effect with a slow start and end
 | cubic-bezier(n, n, n, n) - lets you define your own values in a cubic-bezier
 |                            function
 |
 */

@mixin transition(
	$property: 'all',
	$speed: $speed,
	$animation: 'ease',
	$property-2: 'all',
	$speed-2: $speed,
	$animation-2: 'ease'
) {
	@if $property-2 != null {
		transition: #{$property} $speed #{$animation},
			#{$property-2} $speed-2 #{$animation-2};
	} @else {
		transition: #{$property} $speed #{$animation};
	}
}

/*
 |-----------------------------------------------------------------------------
 | Triangle
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin triangle($direction, $w, $h, $color) {
	@if $direction == 'up' {
		border-color: transparent transparent $color transparent;
		border-width: 0 div($w, 2) $h div($w, 2);
	}

	@if $direction == 'right' {
		border-color: transparent transparent transparent $color;
		border-width: div($h, 2) 0 div($h, 2) $w;
	}

	@if $direction == 'down' {
		border-color: $color transparent transparent transparent;
		border-width: $h div($w, 2) 0 div($w, 2);
	}

	@if $direction == 'left' {
		border-color: transparent $color transparent transparent;
		border-width: div($h, 2) $w div($h, 2) 0;
	}

	@if $direction == 'left-up' {
		border-color: $color transparent transparent;
		border-width: $h $w 0 0;
	}

	border-style: solid;
	content: '';
	height: 0;
	width: 0;
}

/*
 |-----------------------------------------------------------------------------
 | Truncate
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin truncate($value) {
	-webkit-box-orient: vertical;
	display: -webkit-box;
	-webkit-line-clamp: $value;
	overflow: hidden;
}

/*
 |-----------------------------------------------------------------------------
 | Z-Index
 |-----------------------------------------------------------------------------
 |
 | Some description...
 |
 */

@mixin z-index($value) {
	$raw-query: map-get($layers, $value);

	@if $raw-query {
		$query: if(
			type-of($raw-query) == 'string',
			unquote($raw-query),
			inspect($raw-query)
		);

		z-index: #{$query};
	}
}
